@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;200;300;400;500;600&family=Roboto+Mono:wght@100;200;300;500;700&family=Ubuntu:wght@300;500;700&display=swap');

body,
html {
    font-family: 'Ubuntu', sans-serif;
}

.App {
    width: 90%;
    height: 95vh;
    display: grid;
    grid-template-rows: 18% 77% 5%;
    margin: auto;
    cursor: pointer;
}

.page {
    width: 90%;
    height: 95vh;
    display: grid;
    grid-template-rows: 18% 77% 5%;
    margin: auto;
    cursor: pointer;
}

.header {
    position: relative;
    display: grid;
    /* grid-template-rows: 3fr 1fr; */
}

.topheader {
    display: grid;
    grid-template-columns: 1fr 7fr 1fr;
}

.headerlogo {
    width: 60px;
    height: 60px;
    margin: auto 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1.5);
}

.headertitle {
    margin: auto;
    font-size: 2.5em;
    color: rgba(33, 88, 46, 1);
}

.menubar {
    display: flex;
    align-items: center;
}

.menubar a {
    text-decoration: none;
    color: black;
    margin-right: 50px;
}

.menubar :hover {
    color: rgb(84, 213, 82);
}

.menubar a.active {
    color: rgb(36, 175, 52);
    font-weight: bold;
}

.loadingicon {
    position: absolute;
    width: 30px;
    height: 15px;
    right: 5px;
    bottom: 5%;
}

.reportdashboard {
    display: grid;
    grid-template-rows: .75fr .4fr .92fr 1.84fr 1.84fr;
    cursor: pointer;
}

.reporttitlebar {
    display: grid;
    grid-template-columns: 2fr 3fr 1fr 1fr;
}

.cardtitle {
    backdrop-filter: blur(10px);
    border-radius: 4px;
    margin: 4px;
    background-color: #0D6ABF;
    display: grid;
    place-items: center;
}

.cardtitle p {
    color: white;
}

.slicerbarReport {
    background-color: #127bdeb1;
    backdrop-filter: blur(10px);
    border-radius: 4px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin: 4px;
    padding: 20px 10px;
    position: relative;
    z-index: 1;
}

.reportcardbar {
    display: grid;
    grid-template-columns: 2fr repeat(5, 1fr);
}

.card {
    text-align: center;
    backdrop-filter: blur(10px);
    border-radius: 4px;
    margin: 4px;
    position: relative;
}

.guage {
    margin: 4px;
    border-radius: 4px;
    backdrop-filter: blur(10px);
}

.value {
    font-family: "Advent Pro", sans-serif;
    font-weight: 400;
    font-size: 2.75rem;
    left: 50%;
    top: 15%;
    transform: translate(-50%, -50%);
    position: absolute
}

.subtitle {
    font-size: .75rem;
    font-weight: 600;
    margin-left: auto;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
}

.middle-text {
    font-size: 1.9em;
}

.right-text {
    font-size: 1.5em;
}

.left-text {
    font-size: 1.5em;
}

.reportchartbar {
    display: grid;
    grid-template-columns: 5fr 2fr;
}

.reportchartbar2 {
    display: grid;
    grid-template-columns: 2fr 5fr;
}

.mbchart,
.pchart,
.bchart,
.linechart,
.mapchart {
    display: grid;
    place-items: center;
    font-size: small;
    position: relative;
    backdrop-filter: blur(10px);
    border-radius: 4px;
    margin: 4px;
}

.temp {
    display: grid;
    backdrop-filter: blur(10px);
    border-radius: 4px;
    margin: 4px;
    background-color: rgba(0, 0, 0, 0.1);
}

.chartsvg {
    width: 100%;
    height: 90%;
}

.guagesvg {
    width: 100%;
    height: 100%;
}

.bars {
    transform: translate(7.5%, 5%);
    fill: #118DFF;
    font-family: 'Ubuntu', sans-serif;
    cursor: pointer;
}

.pie {
    cursor: pointer;
}

.tooltip {
    pointer-events: none;
}

.percentage-value {
    font-family: "Advent Pro", sans-serif;
}

.slice:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease;
}

.guideline {
    stroke: #444444;
    opacity: 0.5;
}

.tooltip rect {
    fill: rgb(255, 255, 255);
    fill-opacity: .9;
}

.tooltip text {
    fill: black;
    font-family: "Advent Pro", sans-serif;
    font-weight: bold;
}

.series {
    cursor: pointer;
}

.color-legend-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-left: 20px;
}

.color-legend-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 10px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: 20px;
}

.color-legend-topleft {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    position: absolute;
    top: 12.5%;
    left: 0;
    transform: translateY(-50%);
    margin-left: 20px;
}

.color-legend-hidden,
.legend-item-hidden,
.legend-label-hidden {
    display: none;
}

.legend-item-left {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.legend-item-right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.color-box {
    border-radius: 5px;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    margin-left: 5px;
}

.legend-label {
    font-size: .75em;
}

.chart {
    background-color: rgb(146, 146, 232);
    margin: 5px;
    border-radius: 5px;
}

.chart-container {
    transform: translate(7.5%, 15%);
}

.bchart-container {
    transform: translate(10%, 20%);
}

.chart-header {
    position: absolute;
    transform: translate(-50%, 0);
    top: 0;
    left: 50%;
    margin-top: 10px;
    font-size: 1em;
}

.form {
    width: 80%;
    position: relative;
    margin: auto;
}

.slicerlabel {
    font-weight: 300;
    font-size: 1.25em;
    margin-left: 2px;
}

.buttonbar {
    display: flex;
    justify-content: space-between;
}

.left {
    width: 20%;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.right {
    width: 5%;
    display: flex;
    flex-direction: row-reverse;
}

.loginfilm {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    position: fixed;
    width: 100%;
    height: 100%;
    filter: blur(2px);
    top: 0%;
    left: 0%;
    z-index: 2;
}

.login {
    position: fixed;
    width: 20%;
    height: 40%;
    top: 30%;
    left: 40%;
    z-index: 4;
    border-radius: 5px;
    filter: none;

}

.logincontainer {
    display: grid;
    grid-template-rows: 1fr 2fr;
    place-items: center;
    width: 100%;
    height: 80%;
    margin: auto;
}

.logintitle {
    width: 80%;
    display: grid;
    place-items: center;
    margin: auto;
}

.loginform {
    display: grid;
    margin: auto;
    grid-template-rows: repeat(3, 1fr);
}

.loginform input {
    color: grey;
    margin: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid grey;
    border-radius: 4px;
    box-sizing: border-box;
}

.loginform button {
    margin: 5px;
    margin-top: 10px;
    border-radius: 4px;
    padding: 20px;
    cursor: pointer;
    border: none;
    background-color: #0d6abf;
    color: white;
}

.loginform button:hover {
    background-color: #12239e;
}

.loginform button:active {
    color: black;
    background-color: #118dff;
}

.multiselect-dropdown {
    position: relative;
    display: inline-block;
    width: 80%;
    margin: auto;
}

.multiselect-dropdown label {
    font-weight: 400;
    font-size: 1.25em;
    margin-left: 2px;
    color: white;
}

.focusmodal-multiselect-dropdown {
    position: relative;
    display: inline-block;
    width: 80%;
    margin: auto;
}

.focusmodal-multiselect-dropdown label {
    font-weight: 400;
    font-size: 1.25em;
    margin-left: 2px;
    /* color: white; */
}

.dropdown {
    position: relative;
}

.selected-values {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
}

.selected-text {
    font-size: .8em;
    font-weight: 300;
    color: #ffffff;
}

.dropdown-arrow {
    color: #ccc;
}

.options {
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 5;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    padding: 8px;
    cursor: pointer;
}

li:hover {
    background-color: #118dff;
}

@media (max-width: 1400px) or (max-height: 965px) {
    .headertitle {
        font-size: 1.75em;
    }

    .headerlogo {
        margin: auto 2.5px;
        transform: scale(2);
    }

    .slicerbar {
        margin: 2.5px;
        padding: 10px 5px;
    }

    .kpicard {
        margin: 2.5px;
        border-radius: 2.5px;
    }

    .card {
        margin: 2.5px
    }

    .value {
        font-weight: 500;
        font-size: 2em;
    }

    .cardbar {
        border-radius: 2.5px;
    }

    .salestarget {
        height: 123.05px;
        border-radius: 2.5px;
        margin: 2.5px;
    }

    .middle-text {
        font-size: 1.25em;
    }

    .right-text {
        font-size: 1em;
    }

    .left-text {
        font-size: 1em;
    }

    .mbchart,
    .pchart,
    .bchart {
        margin: 2.5px;
        border-radius: 2.5px;
        font-size: .75em;
    }

    .chart-header {
        margin-top: 5px;
        font-size: .75em;
    }

    .legend-label {
        font-size: .7em;
    }

    .footertext {
        margin-left: 2.5px;
        font-size: .5em;
    }

    .title {
        font-size: .75em;
        border-radius: 2.5px;
        margin: 2.5px
    }

    .slicerlabel {
        font-weight: 300;
        font-size: .75em;
        margin-left: 1px;
    }

    .multiselect-container,
    .search-wrapper {
        font-size: 1em;
        color: black;
        padding: 2px;
    }

    .color-legend-right {
        margin-right: 5px;
        padding-right: 5px;
    }

    .color-legend-left {
        margin-left: 5px;
        padding-left: 5px;
    }

    .color-box {
        border-radius: 3px;
        width: 6px;
        height: 6px;
        margin-right: 3px;
        margin-left: 3px;
    }

    .x-axis {
        font-size: .6em;
    }

    .y-axis {
        font-size: .6em;
    }

    .option {
        font-size: 0.7em;
    }
}